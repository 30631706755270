import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import { subscriptionPerks, subscriptionPerksCancellation, subscriptionPerksFull } from '@/constants/SubscriptionPerks';
import { SubscriptionPerksModel } from '@/models/Subscription';

const SubscriptionPerksCard = ({ isFull, isCancel }: { isFull?: boolean; isCancel?: boolean }) => {
  const { t } = useTranslation();
  const [perks, setPerks] = useState<SubscriptionPerksModel[]>(subscriptionPerks);

  useEffect(() => {
    if (isFull) {
      setPerks(subscriptionPerksFull);
      return;
    }

    if (isCancel) {
      setPerks(subscriptionPerksCancellation);
      return;
    }
  }, [isCancel, isFull]);

  return (
    <>
      <div className="card ">
        {perks.map((perk, index: number) => (
          <div key={index}>
            <div className="p8 d-flex">
              <Icon>{perk.icon}</Icon>
              <p className="pl20 text-faded">{t(perk.description)}</p>
              {perk.isNew && <div className="is-new-badge ml-auto text-blue">{t('SUBSCRIPTION.PERKS.NEW')}</div>}
            </div>
            {index !== perks.length - 1 && (
              <div className="d-flex form-divider pt0">
                <div className="line m0"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SubscriptionPerksCard;
