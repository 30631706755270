import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Icon } from '@mui/material';
import { format } from 'date-fns';

import { FeatureFlagEvents } from '@/constants/FeatureFlagEvents';
import { usePlans } from '@/constants/SubscriptionPerks';
import useSubscription from '@/hooks/account/useSubscription';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { SubscriptionPlanModel } from '@/models/Subscription';
import useFeatureFlags from '@/services/FeatureFlag';

import StripeCheckout from '../payments/StripeCheckout';
import SubscriptionPerksCard from './SubscriptionPerksCard';

const SubscriptionPerksAndPaymentPopup = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const { t } = useTranslation();
  const [loadPayment, setLoadPayment] = useState<boolean>(false);
  const [disablePay, setDisablePay] = useState<boolean>(false);
  const { expiresOn } = useSubscription();
  const { captureEvent } = useFeatureFlags();

  const { plans, isLoading, subscriptionData } = usePlans();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanModel>();
  const { breakpointHit } = useBreakpoints();

  useEffect(() => {
    if (!captureEvent) return;
    captureEvent(FeatureFlagEvents.subscriptionModalViewed);
  }, [captureEvent]);

  useEffect(() => {
    if (isLoading || !subscriptionData || !plans) return;
    setSelectedPlan((plans as SubscriptionPlanModel[])[0]);
  }, [isLoading, plans, subscriptionData]);

  return (
    <div className="subscription-modal-container">
      <Button
        className="icon-btn close-button"
        onClick={() => {
          closeModalOutput();
        }}
      >
        <Icon>close</Icon>
      </Button>
      <div className="d-flex h100p flex-wrap">
        <div className="image-panel">
          <div className="subscription-text">
            <div className="d-flex pos-rel z-1000 jc-center gap8">
              <img width={breakpointHit ? '200' : '300'} src="/images/logos/full-text-logo.svg" alt="" />
              <img className={breakpointHit ? '' : 'mt10'} src="/images/logos/subscription-pro-badge.svg" alt="" />
            </div>
            <h4 className="z-1000 pos-rel mt20 text-center">{t('SUBSCRIPTION.GROW-YOUR-FANBASE')}</h4>
          </div>

          <img
            className="singer-image"
            src="https://cdn.unhurd.co.uk/images/web/subscription-banner-image.webp"
            alt=""
          />
        </div>
        <div className="flex-w50p w100p-lg-down overflow-scroll subscription-info-panel">
          <div className="mt100 w80p w90p-lg-down ml-auto mr-auto mt0-lg-down">
            <SubscriptionPerksCard />
          </div>

          <div className="mt48 text-center w80p w90p-lg-down ml-auto mr-auto mb48">
            <h3> {t('SUBSCRIPTION.CHOOSE-A-PLAN')}</h3>
            <p className="mt10 text-faded">{t('SUBSCRIPTION.CHOOSE-A-PLAN-DESCRIPTION')}</p>
            <div className="d-flex jc-center mt20 gap8">
              {!isLoading &&
                plans &&
                (plans as SubscriptionPlanModel[])?.map((plan, index: number) => (
                  <div
                    key={index}
                    className={`card w50p plan-card cursor-pointer ${plan.value === selectedPlan?.value && 'is-selected'}`}
                    onClick={() => setSelectedPlan(plan)}
                  >
                    <div className={`subscription-plan-header ${plan.value === 'annually' ? 'annually' : ''}`}>
                      <h4 className="text-center">{t(plan.title)}</h4>
                    </div>
                    <h2 className="text-center mt48 hide-ms-down">{`£${plan.price.toFixed(2)}`}</h2>
                    <h3 className="text-center mt48 hide-ms-up">{plan.price}</h3>
                    <p className="text-center mt-4">{t(plan.billed)}</p>
                    {plan.value === 'annually' && (
                      <p className="text-center mt20">({'£' + plan.pricePerMonth + ' ' + t(plan.description)})</p>
                    )}
                  </div>
                ))}
            </div>

            <p className="mt20 text-center text-faded">{t('SUBSCRIPTION.SEND-REMINDER-EMAIL')}</p>
            <Button
              className="mt20 btn-white"
              disabled={disablePay}
              onClick={() => {
                setDisablePay(true);
                setLoadPayment(true);
                captureEvent(FeatureFlagEvents.initiateCheckout);
              }}
            >
              {disablePay ? <CircularProgress size={16} /> : t('SUBSCRIPTION.START-MY-FREE-TRIAL')}
            </Button>
            <p className="text-faded small mt20">
              <span>{t('SUBSCRIPTION.FREE-TRIAL-DETAILS')}</span>
              <span> {format(new Date(expiresOn), 'dd/MM/yyyy')} </span>
              <span>{t('SUBSCRIPTION.FREE-TRIAL-DETAILS-2')}</span>
            </p>
            {loadPayment && (
              <StripeCheckout
                product={{
                  productType: 'subscription',
                  priceId: selectedPlan?.priceId || '',
                  platform: 'subscription',
                  orderDetails: {
                    totalValue: selectedPlan?.price || null,
                    totalQuantity: 1,
                    currency: 'GBP',
                    products: [
                      {
                        quantity: 1,
                        productID: selectedPlan?.value === 'monthly' ? 'unhurd_pro_001' : 'unhurd_pro_annual_001',
                        price: selectedPlan?.value === 'monthly' ? 9.99 : 99.99,
                        name:
                          selectedPlan?.value === 'monthly' ? 'un:hurd music Pro Monthly' : 'un:hurd music Pro Yearly',
                        currency: 'GBP',
                      },
                    ],
                  },
                }}
                onComplete={() => {
                  setLoadPayment(false);
                  setDisablePay(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPerksAndPaymentPopup;
