import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Modal } from '@mui/material';

import useSubscription from '@/hooks/account/useSubscription';

import SubscriptionBanner from './SubscriptionBanner';
import SubscriptionPerksAndPaymentPopup from './SubscriptionPerksAndPaymentPopup';

const Subscription = ({ type, element }: { type: string; element?: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [params, setSearchParams] = useSearchParams();

  const { isSubscribed } = useSubscription();

  const handleModalClose = useCallback(() => {
    setSearchParams('');
    setOpen(false);
  }, [setSearchParams]);

  useEffect(() => {
    if (isSubscribed && open) {
      handleModalClose();
    }
  }, [handleModalClose, isSubscribed, open]);

  useEffect(() => {
    if (params.get('subscription')) {
      setOpen(true);
    }
  }, [params]);

  return (
    <>
      <Modal open={open} onClose={handleModalClose}>
        <>
          <SubscriptionPerksAndPaymentPopup
            closeModalOutput={() => {
              handleModalClose();
            }}
          />
        </>
      </Modal>

      {!isSubscribed && (
        <>
          {type === 'banner' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <SubscriptionBanner />
            </div>
          )}
          {type === 'banner-small' && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            >
              <SubscriptionBanner size="small" />
            </div>
          )}
          {type === 'settings' && (
            <div
              onClick={() => {
                setOpen(true);
              }}
            >
              Settings
            </div>
          )}
          {type === 'playlisting' && (
            <div
              onClick={() => {
                setOpen(true);
              }}
            >
              Playlisting
            </div>
          )}
        </>
      )}
      {type === 'element' && element && (
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpen(true);
          }}
        >
          {element}
        </div>
      )}
    </>
  );
};

export default Subscription;
